import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_OVERTIME,
    DELETE_OVERTIME,
    OVERTIME_FORM_TOOGLE_LOADING,
    SET_OVERTIME_LIST,
    SHOW_NOTIFICATION,
    UPDATE_OVERTIME,
} from "../constants";
import { formatOvertimes } from "./settingsActionsUtils";

/* OVERTIME LIST */
export const fetchOvertimes = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.overtimes.list)
            .then((response) => {
                const overtimes = formatOvertimes(response.data);
                dispatch({
                    type: SET_OVERTIME_LIST,
                    payload: keyBy(overtimes, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// OVERTIMES
export const createOvertime = (newOvertime) => {
    return async (dispatch) => {
        dispatch({ type: OVERTIME_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.overtimes.create, newOvertime)
            .then((response) => {
                const overtime = formatOvertimes(response.data);
                dispatch({ type: ADD_NEW_OVERTIME, payload: overtime });
                dispatch({ type: OVERTIME_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Horas extra creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: OVERTIME_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateOvertime = (updatedOvertime) => {
    return async (dispatch) => {
        dispatch({ type: OVERTIME_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.overtimes.edit}${updatedOvertime && updatedOvertime._id}`, updatedOvertime)
            .then((response) => {
                const overtime = formatOvertimes(response.data);
                dispatch({ type: UPDATE_OVERTIME, payload: overtime });
                dispatch({ type: OVERTIME_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Horas extra actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: OVERTIME_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteOvertimes = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: OVERTIME_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.overtimes.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_OVERTIME, payload: Ids });
                dispatch({ type: OVERTIME_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Horas extra eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: OVERTIME_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
